<template lang="pug">
.main-wrapper.centro-lucro-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Centro de Lucro</b>

    Dialog.dialogApagar(header='Remover Centro de Lucro' :visible.sync='dialogApagar' :modal='true')
        p Deseja remover o centro de lucro <b>{{ dialogApagar_data.nm_centro_lucro }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')
    
    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid
            .p-col-12.p-md-4
                label.form-label Centro de Lucro:
                .p-inputgroup
                    InputText(
                        placeholder='Centro de Lucro'
                        @keyup.enter.native='applyFilters()'
                        @input='(value) => { if (!value) applyFilters() }'
                        v-model='filters.nm_centro_lucro'
                    )
                    Button(icon='jam jam-search' @click='applyFilters()')

            .p-col-12.p-md-4
                label.form-label Unidade:
                ProgressBar(v-if="options.unidades.length == 0" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Unidade...'
                        :options='options.unidades'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        @input='getSetor()'
                        v-model='filters.cd_unidade_list'
                        filter
                    )
            .p-col-12.p-md-4
                label.form-label Setor:
                ProgressBar(v-if="loading.setor" mode="indeterminate")
                .p-inputgroup(v-else)
                    Dropdown(
                        placeholder='Selecione Setor...'
                        :options='options.setores'
                        optionLabel='label'
                        optionValue='value'
                        @change='applyFilters()'
                        v-model='filters.cd_setor_list'
                        :disabled="options.setores.length == 0"
                        v-tooltip.bottom="options.setores.length == 0 ? 'Informe a unidade antes' : ''"
                        filter
                    )
    .ta-right.mt-4
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/centro-lucro/salvar/')")
        br
        br

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.nm_centro_lucro" style='position: relative')
                        .ta-left
                            p <b>Centro de Lucro:</b> {{ props.data.nm_centro_lucro }}
                        .ta-left    
                            p <b>Unidades:</b> {{ props.data.cd_unidade_medclub.nm_unidade }}
                        .ta-left    
                            p <b>Setores:</b> {{ props.data.cd_unidade_medclub.nm_unidade }}
                        .ta-right
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/centro-lucro/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Centro de Lucro')
            DataTable(:value="list")
                Column(headerStyle='width: 40%;' field='nm_centro_lucro' header='Centro de Lucro' )
                Column(headerStyle='width: 50%;' field='nm_setor' header='Setor')
                    template(#body='props')
                        p.ta-left(v-for='i in props.data.cd_setor' :key='i.id')
                            span(v-if='i.nm_unidade') <li> <b> Unidade: </b> {{ i.nm_unidade }} <br> <b> Setor: </b> {{ i.nm_setor }} </li>
                Column(headerStyle='width: 10%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/centro-lucro/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>
    
<style lang="scss">
    .centro-lucro-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>
    
<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import MultiSelect from 'primevue/multiselect'
    
    import { CentroLucro, UnidadesMedclub } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        created () { this.applyFilters(), this.getUnidades() },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, MultiSelect,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingUnidades: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
                    nm_centro_lucro: '',
                    cd_unidade_list: '',
                    cd_setor_list: '',
                },
                options:{
                    unidades: [],
                    setores: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                loading: {
                    setor: false,
                },
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return CentroLucro.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            getUnidades (){
                this.waitingUnidades = true
                UnidadesMedclub.findAll().then(response=>{
                    if (response.status == 200) {
                        this.options.unidades.push({label: "- Selecione -", value: null})
                        response.data.forEach(e => {
                            this.options.unidades.push({label: e.nm_unidade, value: e.id})
                        })
                    }
                    this.waitingUnidades = false
                })
            },
            getSetor (){
				this.options.setores = []
				this.filters.cd_setor_list = null
				if (this.filters.cd_unidade_list) {
					this.loading.setor = true
					UnidadesMedclub.findAllSetor({cd_unidade: this.filters.cd_unidade_list}).then(response=>{
						if (response.status == 200) {
							this.options.setores.push({label: "- Selecione -", value: null})
							if (response.data.length == 0) this.$toast.info("Nenhum setor encontrado.", { duration: 3000 });
							response.data.forEach(e => {
								this.options.setores.push({label: e.nm_setor, value: e.id})
							})
						}
					})
					this.loading.setor = false
				}
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                CentroLucro.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Centro de lucro removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>